import { string } from 'prop-types';
import React from 'react';
import { ExternalLink, IconSocialMedia } from '..';
import { injectIntl, intlShape } from '../../util/reactIntl';

import css from './Footer.module.css';

const MapFooterIcons = props => {
  const { data, imgClassName } = props;

  return (
    <div>
      {data &&
        data.map((link, i) => {
          if (link?.url && link?.icon && link?.icon?.data?.attributes?.url) {
            return (
              <ExternalLink
                key={i}
                href={link?.url}
                className={css.icon}
                title={`Go to ${link?.name}`}
              >
                <IconSocialMedia
                  iconPath={link.icon.data?.attributes?.url}
                  imageWidth={link.icon.data?.attributes?.width}
                  imageHeight={link.icon.data?.attributes?.height}
                  title={link?.name}
                  className={imgClassName}
                />
              </ExternalLink>
            );
          }
        })}
    </div>
  );
};

MapFooterIcons.defaultProps = {
  rootClassName: null,
  className: null,
  imgClassName: null,
};

MapFooterIcons.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  imgClassName: string,
};

export default injectIntl(MapFooterIcons);
