import classNames from 'classnames';
import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NamedLink } from '..';
import { injectIntl, intlShape } from '../../util/reactIntl';
import PageSectionLayout from '../PageSectionLayout/PageSectionLayout';
import css from './Footer.module.css';
import FooterColumn from './FooterColumn';
import MapFooterIcons from './MapFooterIcons';

const FOOTER_JSON_LINK =
  'https://raw.githubusercontent.com/DonateDirect/donate-direct-assets/main/footer/Footer.json';

const FooterComponent = props => {
  const { hasError, rootClassName, className } = props;
  const [footerCms, setFooterCms] = useState(undefined);

  useEffect(() => {
    async function fetchFooter() {
      const response = await fetch(FOOTER_JSON_LINK);
      const json = await response.json();
      setFooterCms(json);
    }

    fetchFooter();
  }, []);

  const classes = classNames(rootClassName || css.root, className, css.background);
  return (
    <PageSectionLayout className={classes}>
      <div className={css.content}>
        {!hasError && footerCms && (
          <>
            <div className={css.footerColumnWrapper}>
              {footerCms.footerColumns &&
                footerCms.footerColumns.map((column, i) => (
                  <FooterColumn key={i} column={column} />
                ))}
            </div>
            <div className={`${css.mobileLinks} ${css.showMobile}`}>
              {footerCms.termAndConditionLink && (
                <NamedLink
                  name="CMSPage"
                  params={{ pageId: footerCms.termAndConditionLink?.url }}
                  className={css.mobileChildrenLinks}
                >
                  {footerCms.termAndConditionLink?.text}
                </NamedLink>
              )}
              {footerCms.privacyLink && (
                <NamedLink
                  name="CMSPage"
                  params={{ pageId: footerCms.privacyLink?.url }}
                  className={css.mobileChildrenLinks}
                >
                  {footerCms.privacyLink?.text}
                </NamedLink>
              )}
            </div>
            <div className={css.responsiveItemOrdering}>
              <div className={css.footerIcons}>
                <div>
                  <MapFooterIcons data={footerCms.footerIcons}></MapFooterIcons>
                </div>
                <div className={`${css.responsiveItemOrdering} ${css.hideMobile}`}>
                  {footerCms.termAndConditionLink && (
                    <NamedLink
                      name="CMSPage"
                      params={{ pageId: footerCms.termAndConditionLink?.url }}
                      className={css.link}
                    >
                      {footerCms.termAndConditionLink?.text}
                    </NamedLink>
                  )}
                  {footerCms.privacyLink && (
                    <NamedLink
                      name="CMSPage"
                      params={{ pageId: footerCms.privacyLink?.url }}
                      className={css.link}
                    >
                      {footerCms.privacyLink.text}
                    </NamedLink>
                  )}
                  <div className={css.link}>
                    {footerCms.companyName && <span>{footerCms.companyName} </span>}

                    {footerCms.ABN && <span>ABN: {footerCms.ABN}</span>}
                  </div>
                </div>
              </div>

              <div className={css.social}>
                <h3>{footerCms.findUs?.title}</h3>
                <div className={css.socialIcons}>
                  <MapFooterIcons
                    data={footerCms.findUs?.socialMediaLinks}
                    imgClassName={css.socialIcon}
                  ></MapFooterIcons>
                </div>
              </div>
            </div>
            <div className={`${css.link} ${css.showMobile}`}>
              {footerCms.companyName && <span>{footerCms.companyName} </span>}

              {footerCms.ABN && <span>ABN: {footerCms.ABN}</span>}
            </div>{' '}
          </>
        )}
      </div>
    </PageSectionLayout>
  );
};

FooterComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

FooterComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const Footer = injectIntl(FooterComponent);

export default Footer;
