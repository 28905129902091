import React, { useState, useEffect } from 'react';
import { any, bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const userRole = currentUser?.attributes.profile.metadata?.roles ?? [];

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot =
    notificationCount !== null && notificationCount > 0 ? (
      <div className={css.notificationDot} />
    ) : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.topbarLink}
      name="InboxPage"
      params={{ tab: userRole.includes('COMMUNITY_PARTNER') ? 'orders' : 'sales' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.menuLabel} isOpenClassName={css.menuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.menuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const aboutMenu = (
    <Menu className={classNames(css.hideInMoreMenu)}>
      <MenuLabel className={css.topbarLink} isOpenClassName={css.menuIsOpen}>
        <span className={css.topbarDesktopLabel}>
          <FormattedMessage id="TopbarDesktop.about" />
        </span>
      </MenuLabel>
      <MenuContent className={css.aboutMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink className={css.menuLink} name="CMSPage" params={{ pageId: 'about-us' }}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.aboutDonateDirect" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={css.menuLink}
            name="CMSPage"
            params={{ pageId: 'how-to-donate-items' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.howToDonateItems" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  );

  const isUserUnauthenticatedOrCommunityPartner =
    userRole.includes('COMMUNITY_PARTNER') || !currentUser;
  const communityOrganisationsLinks = isUserUnauthenticatedOrCommunityPartner ? (
    <NamedLink
      name="CMSPage"
      params={{ pageId: 'community-organisations' }}
      className={classNames(css.topbarLink, css.hideInMoreMenu)}
    >
      <span className={css.topbarDesktopLabel}>
        <FormattedMessage id="TopbarDesktop.communityOrganisations" />
      </span>
    </NamedLink>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.topbarLink}>
      <span className={css.topbarDesktopLabel}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.topbarLink}>
      <span className={css.topbarDesktopLabel}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const moreMenuItemsData = [
    {
      key: 'LandingPage',
      component: (
        <NamedLink className={css.menuLink} name="LandingPage">
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.home" />
        </NamedLink>
      ),
    },
    {
      key: 'AboutUsPage',
      component: (
        <NamedLink className={css.menuLink} name="CMSPage" params={{ pageId: 'about-us' }}>
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.aboutDonateDirect" />
        </NamedLink>
      ),
    },
    {
      key: 'HowToDonateItemsPage',
      component: (
        <NamedLink
          className={css.menuLink}
          name="CMSPage"
          params={{ pageId: 'how-to-donate-items' }}
        >
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.howToDonateItems" />
        </NamedLink>
      ),
    },
    isUserUnauthenticatedOrCommunityPartner
      ? {
          key: 'CommunityOrganisationsPage',
          component: (
            <NamedLink
              name="CMSPage"
              params={{ pageId: 'community-organisations' }}
              className={css.menuLink}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.communityOrganisations" />
            </NamedLink>
          ),
        }
      : null,
    {
      key: 'SupportDonateDirectPage',
      component: (
        <NamedLink className={css.menuLink} name="SupportDonateDirectPage">
          <span className={css.menuItemBorder} />
          <FormattedMessage id="TopbarDesktop.supportDonateDirect" />
        </NamedLink>
      ),
    },
  ].filter(item => !!item);

  const moreMenu = (
    <Menu className={css.moreMenu}>
      <MenuLabel className={css.topbarLink} isOpenClassName={css.menuIsOpen}>
        <span className={css.topbarDesktopLabel}>
          <FormattedMessage id="TopbarDesktop.more" />
        </span>
      </MenuLabel>
      <MenuContent className={css.aboutMenuContent}>
        {moreMenuItemsData.map(itemData => (
          <MenuItem key={itemData.key}>{itemData.component}</MenuItem>
        ))}
      </MenuContent>
    </Menu>
  );

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {search}
      <NamedLink className={css.createListingLink} name="NewListingPage">
        <span className={css.topbarDesktopLabel}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
      <NamedLink className={classNames(css.topbarLink, css.hideInMoreMenu)} name="LandingPage">
        <span className={css.topbarDesktopLabel}>
          <FormattedMessage id="TopbarDesktop.home" />
        </span>
      </NamedLink>
      {aboutMenu}
      {communityOrganisationsLinks}
      <NamedLink
        className={classNames(css.topbarLink, css.hideInMoreMenu)}
        name="SupportDonateDirectPage"
      >
        <span className={css.topbarDesktopLabel}>
          <FormattedMessage id="TopbarDesktop.supportDonateDirect" />
        </span>
      </NamedLink>
      {inboxLink}
      {moreMenu}
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
