import isEqual from 'lodash/isEqual';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { LISTING_STATE_PUBLISHED } from '../../util/types';

export const CLOSE_LISTING_REQUEST = 'app/SectionCloseListing/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/SectionCloseListing/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/SectionCloseListing/CLOSE_LISTING_ERROR';

export const GET_OTHER_PUBLISHED_LISTINGS_REQUEST =
  'app/SectionCloseListing/GET_OTHER_PUBLISHED_LISTINGS_REQUEST';
export const GET_OTHER_PUBLISHED_LISTINGS_SUCCESS =
  'app/SectionCloseListing/GET_OTHER_PUBLISHED_LISTINGS_SUCCESS';
export const GET_OTHER_PUBLISHED_LISTINGS_ERROR =
  'app/SectionCloseListing/GET_OTHER_PUBLISHED_LISTINGS_ERROR';

export const CLOSE_MODAL = 'app/SectionCloseListing/CLOSE_MODAL';
export const OPEN_MODAL = 'app/SectionCloseListing/OPEN_MODAL';

const initialState = {
  isModalOpen: false,
  submitInProgress: false,
  hasError: false,
  otherPublishedListings: [],
  getOtherPublishedListingsLoading: true,
  getOtherPublishedListingsError: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_OTHER_PUBLISHED_LISTINGS_REQUEST:
      return {
        ...state,
        getOtherPublishedListingsLoading: true,
        getOtherPublishedListingsError: false,
        otherPublishedListings: [],
      };
    case GET_OTHER_PUBLISHED_LISTINGS_SUCCESS:
      return {
        ...state,
        getOtherPublishedListingsLoading: false,
        otherPublishedListings: payload,
      };
    case GET_OTHER_PUBLISHED_LISTINGS_ERROR:
      return {
        ...state,
        getOtherPublishedListingsLoading: false,
        getOtherPublishedListingsError: true,
        otherPublishedListings: [],
      };
    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        submitInProgress: true,
        hasError: false,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...state,
        submitInProgress: false,
        isModalOpen: false,
      };
    case CLOSE_LISTING_ERROR:
      return {
        ...state,
        submitInProgress: false,
        hasError: true,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
        submitInProgress: false,
        hasError: false,
      };
    case OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
      };
    default:
      return state;
  }
}

export const closeListingRequest = () => ({ type: CLOSE_LISTING_REQUEST });
export const closeListingSuccess = () => ({ type: CLOSE_LISTING_SUCCESS });
export const closeListingError = () => ({ type: CLOSE_LISTING_ERROR });

export const getOtherPublishedListingsRequest = () => ({
  type: GET_OTHER_PUBLISHED_LISTINGS_REQUEST,
});
export const getOtherPublishedListingsSuccess = otherPublishedListings => ({
  type: GET_OTHER_PUBLISHED_LISTINGS_SUCCESS,
  payload: otherPublishedListings,
});
export const getOtherPublishedListingsError = () => ({ type: GET_OTHER_PUBLISHED_LISTINGS_ERROR });

export const closeModal = () => ({ type: CLOSE_MODAL });
export const openModal = () => ({ type: OPEN_MODAL });

export const closeListing = (id, closedDetails, otherListingIdsToClose = []) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(closeListingRequest());

  const ids = [id].concat(otherListingIdsToClose);
  const closePromises = ids.map(id => sdk.ownListings.close({ id }));

  return Promise.all(closePromises)
    .then(() => {
      const updatePromises = ids.map(id =>
        sdk.ownListings.update({ id, publicData: { closedDetails } })
      );
      return Promise.all(updatePromises);
    })
    .then(() => sdk.listings.show({ id }))
    .then(response => {
      dispatch(closeListingSuccess());
      dispatch(addMarketplaceEntities(response));
      return response;
    })
    .catch(() => {
      dispatch(closeListingError());
    });
};

export const openListing = id => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest());
  return sdk.ownListings
    .open({ id })
    .then(() => sdk.ownListings.update({ id, publicData: { closedDetails: undefined } }))
    .then(() => sdk.listings.show({ id }))
    .then(response => {
      dispatch(closeListingSuccess());
      dispatch(addMarketplaceEntities(response));
      return response;
    })
    .catch(() => {
      dispatch(closeListingError());
    });
};

export const getOtherPublishedListings = currentListingId => (dispatch, getState, sdk) => {
  if (!currentListingId) {
    dispatch(getOtherPublishedListingsError());
    return;
  }

  dispatch(getOtherPublishedListingsRequest());
  return sdk.ownListings
    .query({})
    .then(res => {
      const otherPublishedListings = res.data.data.filter(
        x =>
          !isEqual(currentListingId, x.id) &&
          !x.deleted &&
          x.attributes.state === LISTING_STATE_PUBLISHED
      );
      dispatch(getOtherPublishedListingsSuccess(otherPublishedListings));
      return otherPublishedListings;
    })
    .catch(() => {
      dispatch(getOtherPublishedListingsError());
    });
};
