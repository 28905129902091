import classNames from 'classnames';
import React from 'react';
import css from './PageSectionLayout.module.css';

const PageSectionLayout = ({ children, className, style, contentClassName }) => {
  const classes = classNames(className, css.section);
  const contentClasses = classNames(contentClassName, css.sectionContentContainer);

  return (
    <div className={classes} style={style}>
      <div className={contentClasses}>{children}</div>
    </div>
  );
};

export default PageSectionLayout;
