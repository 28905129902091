import React from 'react';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { arrayOf, number, string } from 'prop-types';
import { ITEM_CONDITIONS, propTypes } from '../../../util/types';
import { FormattedMessage } from 'react-intl';
import css from './DonationDetailsPanel.module.css';
import { deliveryOptions } from '../../../util/deliveryOptions';
import IconCheckmark from '../../IconCheckmark/IconCheckmark';
import IconClose from '../../IconClose/IconClose';

const DonationDetailsPanel = props => {
  const { condition, currentStock, selectedDeliveryOptions, location, intl } = props;

  const conditionMessage = ITEM_CONDITIONS.filter(
    itemCondition => itemCondition.key === condition
  )[0]?.label;

  const importantDetails = [
    { title: 'DonationDetailsPanel.conditionLabel', value: conditionMessage },
    { title: 'DonationDetailsPanel.locationLabel', value: location },
    { title: 'DonationDetailsPanel.quantityLabel', value: currentStock },
  ];

  const isLast = index => index !== importantDetails.length - 1;

  return (
    <div className={css.details}>
      <div className={css.importantDetails}>
        {importantDetails.map(({ title, value }, i) => (
          <span key={title} className={css.detailsRow}>
            <span className={css.detailsTitle}>
              <FormattedMessage id={title} />:{' '}
            </span>
            <span className={css.detailsValue}>{value}</span>
            {isLast(i) ? <span className={css.separator}>{' | '}</span> : null}
          </span>
        ))}
      </div>

      <div className={css.deliveryDetails}>
        <h2 className={css.deliveryTitle}>
          <FormattedMessage id="DonationDetailsPanel.deliveryMethodLabel" />
        </h2>
        <ul className={css.deliveryOptions}>
          {deliveryOptions.map(opt => {
            if (selectedDeliveryOptions.includes(opt.value)) {
              return (
                <li className={css.deliveryRow} key={opt.value}>
                  <IconCheckmark rootClassName={css.svgRoot} size="small" />
                  {intl.formatMessage({ id: opt.label })}
                </li>
              );
            }

            return (
              <li className={css.deliveryRow} key={opt.value}>
                <IconClose className={css.svgIcon} />
                {intl.formatMessage({ id: opt.label })}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

DonationDetailsPanel.defaultProps = {
  selectedDeliveryOptions: [],
};

DonationDetailsPanel.propTypes = {
  condition: string.isRequired,
  selectedDeliveryOptions: arrayOf(propTypes.deliveryOption),
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(DonationDetailsPanel);
