import { string } from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import css from './Footer.module.css';

const FooterColumn = props => {
  const { column } = props;

  return (
    <div className={css.footerColumn}>
      <h4>{column.groupHeader}</h4>
      <ul id={column.id}>
        {column.links.map((link, i) => (
          <li key={i}>
            <NamedLink name="CMSPage" params={{ pageId: link.url }} className={css.link}>
              {link.text}
            </NamedLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

FooterColumn.defaultProps = {
  rootClassName: null,
  className: null,
};

FooterColumn.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(FooterColumn);
