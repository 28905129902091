import { getTransaction as getTransactionAPI } from '../../util/api';
import {
  fetchMessages,
  setInitialValues,
  sendMessage as onSendMessage,
} from '../TransactionPage/TransactionPage.duck';
import { sendEnquiry } from './ListingPage.duck';

export const GET_TRANSACTION_REQUEST = 'app/MessagePanel/GET_TRANSACTION_REQUEST';
export const GET_TRANSACTION_SUCCESS = 'app/MessagePanel/GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_ERROR = 'app/MessagePanel/GET_TRANSACTION_ERROR';

const NOT_FOUND = 404;

const initialState = {
  getTransactionError: false,
  isLoading: true,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_TRANSACTION_REQUEST:
      return {
        ...state,
        getTransactionError: false,
        isLoading: true,
        transaction: null,
      };
    case GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transaction: payload.transaction,
      };
    case GET_TRANSACTION_ERROR:
      return {
        ...state,
        getTransactionError: true,
        isLoading: false,
      };
    default:
      return state;
  }
}

export const getTransactionRequest = () => ({ type: GET_TRANSACTION_REQUEST });
export const getTransactionError = () => ({ type: GET_TRANSACTION_ERROR });
export const getTransactionSuccess = (payload = {}) => ({ type: GET_TRANSACTION_SUCCESS, payload });

export const getTransaction = (customerId, listingId) => (dispatch, getState) => {
  dispatch(setInitialValues());
  dispatch(getTransactionRequest());

  if (!customerId) {
    return dispatch(getTransactionSuccess());
  }

  return getTransactionAPI({ customerId, listingId })
    .then(response => {
      const { transaction } = response;
      dispatch(fetchMessages(transaction.id, 1));
      return response;
    })
    .then(response => dispatch(getTransactionSuccess(response)))
    .catch(error => {
      if (error.status === NOT_FOUND) {
        dispatch(getTransactionSuccess());
        return;
      }
      dispatch(getTransactionError());
      return;
    });
};

export const sendMessage = (listingId, message) => async (dispatch, getState, sdk) => {
  if (!message) return;

  const { transaction } = getState().MessagePanel;

  if (transaction) {
    return dispatch(onSendMessage(transaction.id, message));
  }

  const id = await dispatch(sendEnquiry(listingId, message));
  dispatch(fetchMessages(id, 1));
  const {
    data: { data },
  } = await sdk.transactions.show({ id });
  dispatch(getTransactionSuccess({ transaction: data }));

  return;
};
