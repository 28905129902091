import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMedia.module.css';

const IconSocialMedia = props => {
  const {
    rootClassName,
    className,
    imgClassName,
    iconPath,
    imageWidth = 30,
    imageHeight = 30,
    title = '',
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width={`${imageWidth}`}
      height={`${imageHeight}`}
      viewBox={`0 0 ${imageWidth} ${imageHeight}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <image
        className={imgClassName}
        href={iconPath}
        width={`${imageWidth}`}
        height={`${imageHeight}`}
        alt={`${title}`}
      />
    </svg>
  );
};

IconSocialMedia.defaultProps = { rootClassName: null, className: null, imgClassName: null };

const { string } = PropTypes;

IconSocialMedia.propTypes = { rootClassName: string, className: string, imgClassName: string };

export default IconSocialMedia;
