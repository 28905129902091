import { validateInvitationCode, createCommunityOrganisation } from '../../util/api';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import { loginSuccess } from '../../ducks/Auth.duck';

export const VALIDATE_INVITATION_CODE_REQUEST =
  'app/CommunityOrganisationSignupPage/VALIDATE_INVITATION_CODE_REQUEST';
export const VALIDATE_INVITATION_CODE_SUCCESS =
  'app/CommunityOrganisationSignupPage/VALIDATE_INVITATION_CODE_SUCCESS';
export const VALIDATE_INVITATION_CODE_ERROR =
  'app/CommunityOrganisationSignupPage/VALIDATE_INVITATION_CODE_ERROR';

export const CREATE_COMMUNITY_ORGANISATION_REQUEST =
  'app/CommunityOrganisationSignupPage/CREATE_COMMUNITY_ORGANISATION_REQUEST';
export const CREATE_COMMUNITY_ORGANISATION_SUCCESS =
  'app/CommunityOrganisationSignupPage/CREATE_COMMUNITY_ORGANISATION_SUCCESS';
export const CREATE_COMMUNITY_ORGANISATION_ERROR =
  'app/CommunityOrganisationSignupPage/CREATE_COMMUNITY_ORGANISATION_ERROR';

const initialState = {
  validateInProgress: true,
  createInProgress: false,
  accountCreated: false,
  signupError: null,
  isValid: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case VALIDATE_INVITATION_CODE_REQUEST:
      return {
        ...state,
        validateInProgress: true,
        accountCreated: false,
        signupError: null,
        isValid: false,
      };
    case CREATE_COMMUNITY_ORGANISATION_REQUEST:
      return {
        ...state,
        createInProgress: true,
        accountCreated: false,
        signupError: null,
      };
    case VALIDATE_INVITATION_CODE_SUCCESS:
      return {
        ...state,
        validateInProgress: false,
        isValid: true,
      };
    case CREATE_COMMUNITY_ORGANISATION_SUCCESS:
      return {
        ...state,
        createInProgress: false,
        accountCreated: true,
      };
    case VALIDATE_INVITATION_CODE_ERROR:
      return {
        ...state,
        validateInProgress: false,
        isValid: false,
      };
    case CREATE_COMMUNITY_ORGANISATION_ERROR:
      return {
        ...state,
        createInProgress: false,
        signupError: payload,
      };
    default:
      return state;
  }
}

export const validateInvitationCodeRequest = () => ({ type: VALIDATE_INVITATION_CODE_REQUEST });
export const validateInvitationCodeError = () => ({ type: VALIDATE_INVITATION_CODE_ERROR });
export const validateInvitationCodeSuccess = () => ({ type: VALIDATE_INVITATION_CODE_SUCCESS });

export const createCommunityOrganisationRequest = () => ({
  type: CREATE_COMMUNITY_ORGANISATION_REQUEST,
});
export const createCommunityOrganisationError = error => ({
  type: CREATE_COMMUNITY_ORGANISATION_ERROR,
  payload: storableError(error),
});
export const createCommunityOrganisationSuccess = () => ({
  type: CREATE_COMMUNITY_ORGANISATION_SUCCESS,
});

export const validateInviteCode = invitationCode => (dispatch, getState) => {
  if (!invitationCode) {
    dispatch(validateInvitationCodeError());
  } else {
    dispatch(validateInvitationCodeRequest());
    return validateInvitationCode({ invitationCode })
      .then(({ valid }) => {
        valid ? dispatch(validateInvitationCodeSuccess()) : dispatch(validateInvitationCodeError());
      })
      .catch(() => {
        dispatch(validateInvitationCodeError());
      });
  }
};

export const createCommunityOrg = userInfo => (dispatch, getState) => {
  dispatch(createCommunityOrganisationRequest());
  return createCommunityOrganisation(userInfo)
    .then(() => dispatch(createCommunityOrganisationSuccess()))
    .then(() => dispatch(loginSuccess()))
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => {
      dispatch(createCommunityOrganisationError(e));
    });
};
