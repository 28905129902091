import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { createImageVariantConfig } from '../../util/sdkLoader';

export const GET_AUTHOR_LISTINGS_REQUEST = 'app/SectionOtherListings/GET_AUTHOR_LISTING_REQUEST';
export const GET_AUTHOR_LISTINGS_SUCCESS = 'app/SectionOtherListings/GET_AUTHOR_LISTING_SUCCESS';
export const GET_AUTHOR_LISTINGS_ERROR = 'app/SectionOtherListings/GET_AUTHOR_LISTING_ERROR';

const initialState = {
  isLoading: true,
  hasError: false,
  otherListings: [],
};

const resultIds = payload => {
  return payload.map(l => l.id);
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_AUTHOR_LISTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        otherListings: [],
      };
    case GET_AUTHOR_LISTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        otherListings: payload,
      };
    case GET_AUTHOR_LISTINGS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      return state;
  }
}

export const getAuthorListingsRequest = () => ({ type: GET_AUTHOR_LISTINGS_REQUEST });
export const getAuthorListingsSuccess = payload => ({ type: GET_AUTHOR_LISTINGS_SUCCESS, payload });
export const getAuthorListingsError = () => ({ type: GET_AUTHOR_LISTINGS_ERROR });

export const getAuthorListings = (authorId, listingId, amount = 3) => (dispatch, getState, sdk) => {
  dispatch(getAuthorListingsRequest());
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  return sdk.listings
    .query({
      authorId,
      sort: '-createdAt',
      page: 1,
      // get two extra than how many we want to show incase the current listing is in there and to check if the user has more than three
      perPage: amount + 2,
      include: ['author', 'images'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [
        //cropped for thumbnails
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
        `variants.${variantPrefix}-4x`,
        //scaled variants for large image
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
      'limit.images': 1,
    })
    .then(response => {
      const denormalisedResponse = denormalisedResponseEntities(response);
      const otherListings = denormalisedResponse.filter(
        listing => listing.id.uuid !== listingId.uuid
      );
      dispatch(getAuthorListingsSuccess(otherListings));
      return otherListings;
    })
    .catch(error => {
      dispatch(getAuthorListingsError());
    });
};
