import React from 'react';
import css from './NotFoundPage.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import LocationSearchForm from './LocationSearchForm/LocationSearchForm';

const NotFoundContent = () => {
  const handleSearchSubmit = values => {
    const { search, selectedPlace } = values.location;
    const { origin, bounds } = selectedPlace;
    const searchParams = { address: search, origin, bounds };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };
  return (
    <div className={css.root}>
      <div className={css.content}>
        <div className={css.number}>404</div>
        <h1 className={css.heading}>
          <FormattedMessage id="NotFoundPage.heading" />
        </h1>
        <p className={css.description}>
          <FormattedMessage id="NotFoundPage.description" />
        </p>
        <LocationSearchForm className={css.searchForm} onSubmit={handleSearchSubmit} />
      </div>
    </div>
  );
};

export default NotFoundContent;
