import React from 'react';
import { FormattedMessage } from '../util/reactIntl';

/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

const notMetalOrWireFrame = {
  key: 'not-metal-or-wire-frame',
  label: <FormattedMessage id="Validation.NotMetalOrWire" />,
};

const hasInstructions = {
  key: 'frame-has-instructions',
  label: <FormattedMessage id="Validation.HasInstructions" />,
};

const wipedPersonalData = {
  key: 'wiped-personal-data',
  label: <FormattedMessage id="Validation.WipedPersonalData" />,
};

const includedChargingCable = {
  key: 'included-charging-cable',
  label: <FormattedMessage id="Validation.IncludedChargingCable" />,
};

const freshlyLaunderedOrDryCleaned = {
  key: 'freshly-laundered-or-dry-cleaned',
  label: <FormattedMessage id="Validation.FreshlyLaunderedOrDryCleaned" />,
};

const dryCleaned = {
  key: 'dry-cleaned',
  label: <FormattedMessage id="Validation.DryCleaned" />,
};

const unopenedNewUnused = {
  key: 'unopened-new-unused',
  label: <FormattedMessage id="Validation.UnopenedOrUnused" />,
};

const sixMonthsBeforeExpiry = {
  key: 'six-months-before-expiry',
  label: <FormattedMessage id="Validation.SixMonthsBeforeExpiry" />,
};

const thoroughlyCleaned = {
  key: 'thoroughly-cleaned',
  label: <FormattedMessage id="Validation.ThoroughlyCleaned" />,
};

export const filters = [
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'beds',
          label: 'Beds',
          subcategories: [
            {
              key: 'electric-bed',
              label: 'Electric Bed',
              maximumAge: 5,
              isElectrical: true,
            },
            { key: 'mattress-single', label: 'Mattress (Single)' },
            {
              key: 'base-frame-single',
              label: 'Base/Frame (Single)',
              criteria: [notMetalOrWireFrame, hasInstructions],
            },
            {
              key: 'base-frame-mattress-single',
              label: 'Base/Frame & Mattress (Single)',
              criteria: [notMetalOrWireFrame, hasInstructions],
            },
            { key: 'mattress-double', label: 'Mattress (Double)' },
            {
              key: 'base-frame-double',
              label: 'Base/Frame (Double)',
              criteria: [notMetalOrWireFrame, hasInstructions],
            },
            {
              key: 'base-frame-mattress-double',
              label: 'Base/Frame & Mattress (Double)',
              criteria: [notMetalOrWireFrame, hasInstructions],
            },
            { key: 'mattress-king', label: 'Mattress (King)' },
            {
              key: 'base-frame-king',
              label: 'Base/Frame (King)',
              criteria: [notMetalOrWireFrame, hasInstructions],
            },
            {
              key: 'base-frame-mattress-king',
              label: 'Base/Frame & Mattress (King)',
              criteria: [notMetalOrWireFrame, hasInstructions],
            },
            { key: 'mattress-king-single', label: 'Mattress (King Single)' },
            {
              key: 'base-frame-king-single',
              label: 'Base/Frame (King Single)',
              criteria: [notMetalOrWireFrame, hasInstructions],
            },
            {
              key: 'base-frame-mattress-king-single',
              label: 'Base/Frame & Mattress (King Single)',
              criteria: [notMetalOrWireFrame, hasInstructions],
            },
            { key: 'mattress-queen', label: 'Mattress (Queen)' },
            {
              key: 'base-frame-queen',
              label: 'Base/Frame (Queen)',
              criteria: [notMetalOrWireFrame, hasInstructions],
            },
            {
              key: 'base-frame-mattress-queen',
              label: 'Base/Frame & Mattress (Queen)',
              criteria: [notMetalOrWireFrame, hasInstructions],
            },
          ],
        },
        {
          key: 'furniture',
          label: 'Furniture',
          subcategories: [
            { key: 'desk-chair', label: 'Desk chair' },
            { key: 'desk', label: 'Desk' },
            { key: 'dining-chair', label: 'Dining ' },
            { key: 'armchair', label: 'Armchair' },
            { key: 'couch-lounge-suite', label: 'Couch / Lounge suite' },
            { key: 'coffee-table', label: 'Coffee table' },
            { key: 'buffet', label: 'Buffet' },
            { key: 'recliner', label: 'Recliner' },
            { key: 'recliner-electric', label: 'Recliner (Electric)', isElectrical: true },
            { key: 'dining-table', label: 'Dining table' },
            { key: 'chest-of-drawers', label: 'Chest of drawers' },
            {
              key: 'tv-entertainment-unit',
              label: 'TV Entertainment unit',
            },
            { key: 'bedside-table', label: 'Bedside Table' },
          ],
        },
        {
          key: 'kitchenware',
          label: 'Kitchenware',
          subcategories: [
            { key: 'pots-pan', label: 'Pots & Pan' },
            { key: 'mugs-cups-or-glass', label: 'Mugs, cups or glass' },
            { key: 'bowl', label: 'Bowl' },
            { key: 'cutlery', label: 'Cutlery' },
            { key: 'kitchen-utensils', label: 'Kitchen utensils' },
            { key: 'baking-dish', label: 'Baking dish' },
            { key: 'bowl-soup-desert', label: 'Bowl (soup/desert)' },
            {
              key: 'crockery-set-4-place-settings',
              label: 'Crockery set (4 place settings)',
            },
            { key: 'cutlery-piece', label: 'Cutlery piece' },
            { key: 'cutlery-set-4-place-settings', label: 'Cutlery set (4 place settings)' },
            { key: 'cup', label: 'Cup' },
            { key: 'cutting-board', label: 'Cutting board' },
            { key: 'dish-rack', label: 'Dish rack' },
            { key: 'frypan', label: 'Frypan' },
            {
              key: 'kitchen-utensils-eg.,-spatula',
              label: 'Kitchen utensils (eg., spatula)',
            },
            { key: 'mixing-bowl', label: 'Mixing Bowl' },
            { key: 'mug', label: 'Mug' },
            { key: 'plate', label: 'Plate' },
            { key: 'pot-saucepan', label: 'Pot/saucepan' },
            { key: 'salad-bowl-fruit-bowl', label: 'Salad bowl/Fruit bowl' },
            { key: 'serving-platter', label: 'Serving platter' },
            { key: 'food-storage-container', label: 'Food storage container' },
            { key: 'tea-towel', label: 'Tea towel' },
          ],
        },
        {
          key: 'linen',
          label: 'Linen',
          subcategories: [
            {
              key: 'bed-linen-set',
              label: 'Bed linen set',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'blanket-queen',
              label: 'Blanket (Queen)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'doona-quilt-queen',
              label: 'Doona/quilt (Queen)',
              criteria: [dryCleaned],
            },
            {
              key: 'doona-quilt-cover-queen',
              label: 'Doona/quilt cover (Queen)',
              criteria: [dryCleaned],
            },
            {
              key: 'fitted-sheet-queen',
              label: 'Fitted sheet (Queen)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'flat-sheet-queen',
              label: 'Flat sheet (Queen)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'mattress-protector-queen',
              label: 'Mattress protector  (Queen)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'blanket-king',
              label: 'Blanket (King)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'doona-quilt-king',
              label: 'Doona/quilt (King)',
              criteria: [dryCleaned],
            },
            {
              key: 'doona-quilt-cover-king',
              label: 'Doona/quilt cover (King)',
              criteria: [dryCleaned],
            },
            {
              key: 'fitted-sheet-king',
              label: 'Fitted sheet (King)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'flat-sheet-king',
              label: 'Flat sheet  (King)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'mattress-protector-king',
              label: 'Mattress protector  (King)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'blanket-double',
              label: 'Blanket (Double)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'doona-quilt-double',
              label: 'Doona/quilt (Double)',
              criteria: [dryCleaned],
            },
            {
              key: 'doona-quilt-cover-double',
              label: 'Doona/quilt cover (Double)',
              criteria: [dryCleaned],
            },
            {
              key: 'fitted-sheet-double',
              label: 'Fitted sheet (Double)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'Flat sheet (Double)',
              label: 'Flat sheet (Double)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'mattress-protector-double',
              label: 'Mattress protector (Double)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'blanket-single',
              label: 'Blanket (Single)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'doona-quilt-single',
              label: 'Doona/quilt (Single)',
              criteria: [dryCleaned],
            },
            {
              key: 'doona-quilt-cover-single',
              label: 'Doona/quilt cover (Single)',
              criteria: [dryCleaned],
            },
            {
              key: 'fitted-sheet-single',
              label: 'Fitted sheet (Single)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'flat-sheet-single',
              label: 'Flat sheet (Single)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'mattress-protector-single',
              label: 'Mattress protector (Single)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'blanket-king-single',
              label: 'Blanket (King Single)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'doona-quilt-king-single',
              label: 'Doona/quilt (King Single)',
              criteria: [dryCleaned],
            },
            {
              key: 'doona-quilt-cover-king-single',
              label: 'Doona/quilt cover (King Single)',
              criteria: [dryCleaned],
            },
            {
              key: 'fitted-sheet-king-single',
              label: 'Fitted sheet (King Single)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'flat-sheet-king-single',
              label: 'Flat sheet (King Single)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'mattress-protector-king-single',
              label: 'Mattress protector (King Single)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'pillows-new-only',
              label: 'Pillows – new only',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            { key: 'pillow-case', label: 'Pillow case', criteria: [freshlyLaunderedOrDryCleaned] },
            {
              key: 'towel-bath-towel',
              label: 'Towel (bath towel)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
            {
              key: 'towel-hand-towel',
              label: 'Towel (hand towel)',
              criteria: [freshlyLaunderedOrDryCleaned],
            },
          ],
        },
        {
          key: 'toiletries',
          label: 'Toiletries',
          subcategories: [
            {
              key: 'body-wash',
              label: 'Body wash',
              criteria: [unopenedNewUnused, sixMonthsBeforeExpiry],
            },
            {
              key: 'conditioner',
              label: 'Conditioner',
              criteria: [unopenedNewUnused, sixMonthsBeforeExpiry],
            },
            {
              key: 'deodorant-rollon-only',
              label: 'Deodorant (roll-on only)',
              criteria: [unopenedNewUnused, sixMonthsBeforeExpiry],
            },
            {
              key: 'disposable-razors',
              label: 'Disposable razors',
              criteria: [unopenedNewUnused, sixMonthsBeforeExpiry],
            },
            {
              key: 'incontinence-aids',
              label: 'Incontinence aids',
              criteria: [unopenedNewUnused, sixMonthsBeforeExpiry],
            },
            {
              key: 'menstrual-care-products',
              label: 'Menstrual care products',
              criteria: [unopenedNewUnused, sixMonthsBeforeExpiry],
            },
            {
              key: 'moisturiser',
              label: 'Moisturiser',
              criteria: [unopenedNewUnused, sixMonthsBeforeExpiry],
            },
            {
              key: 'shampoo',
              label: 'Shampoo',
              criteria: [unopenedNewUnused, sixMonthsBeforeExpiry],
            },
            { key: 'soap', label: 'Soap', criteria: [unopenedNewUnused, sixMonthsBeforeExpiry] },
            {
              key: 'tissues',
              label: 'Tissues',
              criteria: [unopenedNewUnused, sixMonthsBeforeExpiry],
            },
            {
              key: 'toilet-paper',
              label: 'Toilet paper',
              criteria: [unopenedNewUnused, sixMonthsBeforeExpiry],
            },
            {
              key: 'toiletry-bag',
              label: 'Toiletry bag',
              criteria: [unopenedNewUnused, sixMonthsBeforeExpiry],
            },
            {
              key: 'toothbrush',
              label: 'Toothbrush',
              criteria: [unopenedNewUnused, sixMonthsBeforeExpiry],
            },
            {
              key: 'toothpaste',
              label: 'Toothpaste',
              criteria: [unopenedNewUnused, sixMonthsBeforeExpiry],
            },
          ],
        },
        {
          key: 'whitegoods-appliances',
          label: 'Whitegoods & Appliances',
          subcategories: [
            { key: 'dishwasher', label: 'Dishwasher', isElectrical: true },
            {
              key: 'fridge-freezer',
              label: 'Fridge/freezer',
              criteria: [thoroughlyCleaned],
              isElectrical: true,
            },
            {
              key: 'fridge-bar-fridge',
              label: 'Fridge/bar fridge',
              criteria: [thoroughlyCleaned],
              isElectrical: true,
            },
            {
              key: 'lamp',
              label: 'Lamp',
              criteria: [
                {
                  key: 'removed-bulbs',
                  label: <FormattedMessage id="Validation.RemovedGlobes" />,
                },
              ],
              isElectrical: true,
            },
            {
              key: 'microwave',
              label: 'Microwave',
              criteria: [thoroughlyCleaned],
              isElectrical: true,
            },
            {
              key: 'television',
              label: 'Television',
              criteria: [
                {
                  key: 'comes-with-stand-and-remote',
                  label: <FormattedMessage id="Validation.StandWithRemote" />,
                },
              ],
              isElectrical: true,
            },
            { key: 'vacuum-cleaner', label: 'Vacuum cleaner', isElectrical: true },
            { key: 'washing-machine', label: 'Washing machine', isElectrical: true },
          ],
        },
        {
          key: 'household-items',
          label: 'Household Items',
          subcategories: [
            { key: 'backpack', label: 'Backpack' },
            { key: 'bucket', label: 'Bucket' },
            { key: 'clothes-airer', label: 'Clothes airer' },
            { key: 'dustpan-and-brush', label: 'Dustpan and brush' },
            { key: 'ironing-board', label: 'Ironing board' },
            { key: 'laundry-basket', label: 'Laundry basket' },
            { key: 'suitcase', label: 'Suitcase' },
          ],
        },
        {
          key: 'computers-mobile-devices',
          label: 'Computers & Mobile Devices',
          subcategories: [
            {
              key: 'computer-monitor-and-hard-drive',
              label: 'Computer monitor and hard drive',
              criteria: [wipedPersonalData, includedChargingCable],
              isElectrical: true,
            },
            {
              key: 'ipad',
              label: 'iPad',
              criteria: [wipedPersonalData, includedChargingCable],
              isElectrical: true,
            },
            {
              key: 'kindles',
              label: 'Kindles',
              criteria: [wipedPersonalData, includedChargingCable],
              isElectrical: true,
            },
            {
              key: 'laptop-with-charging-cable',
              label: 'Laptop with charging cable',
              criteria: [wipedPersonalData, includedChargingCable],
              isElectrical: true,
            },
            {
              key: 'laptop-bag',
              label: 'Laptop bag',
            },
            {
              key: 'mobile-phone',
              label: 'Mobile phone',
              criteria: [wipedPersonalData, includedChargingCable],
              isElectrical: true,
            },
            {
              key: 'video-game-console',
              label: 'Video game console',
              criteria: [wipedPersonalData, includedChargingCable],
              isElectrical: true,
            },
          ],
        },
        {
          key: 'clothes',
          label: 'Clothes',
          subcategories: [
            { key: 'bra', label: 'Bra' },
            { key: 'coat-men', label: 'Coat – men' },
            { key: 'coat-women', label: 'Coat - women' },
            { key: 'dress', label: 'Dress' },
            { key: 'hosiery-new-only', label: 'Hosiery (new only)' },
            { key: 'jeans-men', label: 'Jeans – men' },
            { key: 'jeans-women', label: 'Jeans – women' },
            { key: 'maternity-clothes', label: 'Maternity clothes' },
            { key: 'mixed-clothes-men', label: 'Mixed clothes – men' },
            { key: 'mixed-clothes-women', label: 'Mixed clothes – women' },
            { key: 'pajamas-men', label: 'pajamas – men' },
            { key: 'pajamas-women', label: 'pajamas - women' },
            { key: 'shirt-men', label: 'Shirt – men' },
            { key: 'shirt-women', label: 'Shirt- women' },
            { key: 'shorts-men', label: 'Shorts- men' },
            { key: 'shorts-women', label: 'Shorts - women' },
            { key: 'skirt', label: 'Skirt' },
            { key: 'socks-men', label: 'Socks – men' },
            { key: 'socks-women', label: 'Socks - women' },
            { key: 'tshirt-men', label: 'T-shirt – men' },
            { key: 'tshirt-women', label: 'T-shirt – women' },
            { key: 'underwear-new-only-women', label: 'Underwear (new only) - women' },
            { key: 'underwear-new-only-men', label: 'Underwear (new only) – men' },
            { key: 'work-clothes-men', label: 'Work clothes – men' },
            { key: 'work-clothes-women', label: 'Work clothes - women' },
          ],
        },
        {
          key: 'miscellaneous',
          label: 'Miscellaneous',
          subcategories: [],
        },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    // { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: ['category'],
};
